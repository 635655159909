<template>
  <div class="checkWrapBg">
    <div class="checkWrap">
      <img src="@/assets/img/checkImg.svg" />
      <h1>지금은 사이트 점검 중입니다.</h1>
      <p>사이트 점검시간 : {{dateStrKorean(siteCheck ? siteCheck.startDate:null)}} ~ {{dateStrKorean(siteCheck ? siteCheck.endDate:null)}}</p>
      <span>{{title}} 에서는 보다 나은 서비스를 제공하기 위해 시스템 점검이 진행될 예정입니다.<br />시스템 점검으로 인해 서비스 이용에 불편을 드리는 점 양해 부탁드립니다.</span>
      <em>{{title}}</em>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'

export default {
  name: 'check',
  components: {
  },
  data () {
    return {
      title: process.env.VUE_APP_TITLE || 'Moka'
    }
  },
  created () {
    this.loadCheckOption()
  },
  computed: {
    ...mapState([
      'siteCheck'
    ])
  },
  methods: {
    loadCheckOption () {
      store.dispatch('storeSiteCheck')
    }
  }
}
</script>
<style scoped>
.checkWrapBg {background: #000;height: 100vh;display: flex;align-items: center;justify-content: center;}
.checkWrap {text-align: center;color: #fff;}
.checkWrap h1 {font-size: 40px;}
.checkWrap p {font-size: 22px;margin-top: 10px;}
.checkWrap span {margin-top: 30px;display: block;font-size: 16px;line-height: 1.5em;}
.checkWrap em {padding: 110px 0 80px;display: block;font-size: 29px;font-weight: bold;}
@media (max-width: 700px) {
  .checkWrap {padding: 0 20px;}
  .checkWrap img {width: 330px;}
  .checkWrap h1 {font-size: 26px;}
  .checkWrap p {font-size: 14px;}
  .checkWrap span {margin-top: 30px;font-size: 14px;}
  .checkWrap span br {display: none;}
  .checkWrap em {padding: 70px 0 40px;}
}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
